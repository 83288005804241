(function ($) {
    // textarea functionality 
    let textareaContainer = $('.form__textarea')

    textareaContainer.each((i, ele) => {
        let textarea = $(ele).find('textarea')
        let maxValue = textarea.attr('maxlength')
        let count = $(ele).find('.form__textarea-count')

        textarea.get(0).set = ()=> {
            let current = textarea.get(0).value.length
            let max = parseInt(maxValue)

            count.get(0).innerText =  current + '/' + max
        }

        textarea.get(0).set()
        
        textarea.on('change keyup paste cut', ()=> textarea.get(0).set() )
    })
})(jQuery)